import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 28,
  pages: 16,
  zoom: 75,
  paragraphs: [
    {
      offset: 2,
      factor: 1.75,
      header: "The Heterometa",
      image: "/01.png",
      aspect: 1.51,
      text:" "
      // text: "Heterometa is metaverse of Andromeda where is all ideas came from to our world. There is only one gateway to reach HeteroMeta which is Earth 2.0. A gateway opened to Heterometa just when we need it. We prepare ourselves to new life form. We need a reset our lifestyle. We need more sustainable way to satisfy our true needs."
    },
    {
      offset: 5,
      factor: 3.0,
      header: "Company Purposes",
      image: "/02.png",
      aspect: 1.5,
      text:" "
      // text: "he world was a beautiful place and it's getting more and more barren. The world has always been generous and forgiving towards people who use their resources kindlessly. Each time, the world managed to heal its own wounds against those who consumed it. We are getting more and more crowded day by day and we are creating new fashions and lifestyles every day. These changes have positive and negative reflections. The world needs to be protected against this ever-changing and ever-increasing consumption. Otherwise, we will create an unlivable world and destroy the future."
    },
    {
      offset: 6.3,
      factor: 2.25,
      header: "Solution",
      image: "/03.png",
      aspect: 1.5037,
      text:" "
      // text: "Prepare yourself to HeteroMeta. Follow the seeds of new life. Get rid of those who expect you to fit in the world, stay away from your wishes and your life stuck on the limits. Find out what you really need."
    },
    {
      offset: 8.0,
      factor: 2.0,
      header: "Philosopy",
      image: "/04.png",
      aspect: 1.665,
      text:" "
      // text: "Prepare yourself to HeteroMeta. Follow the seeds of new life. Get rid of those who expect you to fit in the world, stay away from your wishes and your life stuck on the limits. Find out what you really need"
    },
    {
      offset: 10.0,
      factor: 1.75,
      header: "Adoption Strategy",
      image: "/05.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 12.0,
      factor: 1.75,
      header: "Roadmap",
      image: "/06.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 14.0,
      factor: 1.75,
      header: "Ecosystem",
      image: "/07.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 16.0,
      factor: 1.75,
      header: "Ecosystem",
      image: "/08.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 18.0,
      factor: 1.75,
      header: "Earth 2.0",
      image: "/09.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 20.0,
      factor: 1.75,
      header: "Dimenzia ",
      image: "/10.png",
      aspect: 1.55,
      text:" Brand Verification"
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 22.0,
      factor: 1.75,
      header: "Brand",
      image: "/11.png",
      aspect: 1.55,
      text:"Brand Verification"
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 24.0,
      factor: 1.75,
      header: "Beyondmarket",
      image: "/12.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    {
      offset: 26.0,
      factor: 1.75,
      header: "I'mmerse",
      image: "/13.png",
      aspect: 1.55,
      text:" "
      // text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    },
    // {
    //   offset: 10.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/6.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 11.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/7.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 12.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/9.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 14.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/9.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 15.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/9.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 16.0,
    //   factor: 1.75,
    //   header: "True Needs",
    //   image: "/9.jpeg",
    //   aspect: 1.55,
    //   text: "Do you need a phone or an iPhone? Do you need shoes or do you need Nike? A life form where you can reach what you really want is waiting for you in HeteroMeta. From your old habits to the lives you can't reach. Start a journey inside the deep world of HeteroMeta."
    // },
    // {
    //   offset: 17.0,
    //   factor: 1.75,
    //   header: "The HeteroMeta",
    //   image: "/10.jpeg",
    //   aspect: 1.77,
    //   text: "Education and enlightenment."
    // }
    // { offset: 27.0, factor: 1.5, header: "The HeteroMeta", aspect: 1.77, text: "Education and enlightenment." }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 }
  ],
  diamonds: [
    { x: 0, offset: 0, pos: new Vector3(), scale: 30, factor: 4 },
    // { x: 2, offset: 2.1, pos: new Vector3(), scale: 8.8, factor: 2.1 },
    // { x: -5, offset: 3, pos: new Vector3(), scale: 7.8, factor: 2.5 },
    // { x: 0, offset: 4.2, pos: new Vector3(), scale: 7.8, factor: 1.75 },
    // { x: 0, offset: 5, pos: new Vector3(), scale: 7.8, factor: 2.5 },
    // { x: 2, offset: 6.5, pos: new Vector3(), scale: 9.25, factor: 0.85 },
    // { x: -5, offset: 8, pos: new Vector3(), scale: 8.8, factor: 2 },
     { x: 0, offset: 27, pos: new Vector3(), scale: 10.5, factor: 6 }
  ],
  top: createRef()
}

export default state
