import ReactDOM from "react-dom"
import React, { Suspense, useEffect, useRef, useMemo } from "react"
import { Canvas, useLoader, useFrame } from "@react-three/fiber"
import { Html, Stars } from "@react-three/drei"
import { TextureLoader, LinearFilter } from "three"
import lerp from "lerp"
import { Text, MultilineText } from "./components/Text"
import Diamonds from "./diamonds/Diamonds"
import Plane from "./components/Plane"
import { Block, useBlock } from "./blocks"
import state from "./store"
import "./styles.css"

function Startup() {
  const ref = useRef()
  useFrame(() => (ref.current.material.opacity = lerp(ref.current.material.opacity, 0, 0.025)))
  return <Plane ref={ref} color="#E2C7E5" position={[0, 0, 200]} scale={[100, 100, 1]} />
}

function Paragraph({ image, index, offset, factor, header, aspect, text }) {
  const { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock()
  const size = aspect < 1 && !mobile ? 0.65 : 1
  const alignRight = (canvasWidth - w * size - margin) / 2
  const pixelWidth = w * state.zoom * size
  const left = !(index % 2)
  // const color = index % 2 ? "#D40749" : "#2FE8C3"
  const color = index % 2 ? "#ffeab1" : "#a2dce0"
  return (
    <Block factor={factor} offset={offset}>
      <group position={[left ? -alignRight : alignRight, 0, 0]}>
        <Plane map={image} args={[1, 1, 32, 32]} shift={75} size={size} aspect={aspect} scale={[w * size, (w * size) / aspect, 1]} frustumCulled={false} />
        <Html
          style={{ width: pixelWidth / (mobile ? 1 : 2), textAlign: left ? "left" : "right" }}
          position={[left || mobile ? (-w * size) / 2 : 0, (-w * size) / 2 / aspect - 0.4, 1]}>
          <div tabIndex={index}>{text}</div>
        </Html>
        <Text left={left} right={!left} size={w * 0.04} color={color} top position={[((left ? -w : w) * size) / 2, (w * size) / aspect / 2 + 0.5, -1]}>
          {header}
        </Text>
        <Block factor={0.2}>
          <Text opacity={0.5} size={w * 0.5} color="#1A1E2A" position={[((left ? w : -w) / 2) * size, (w * size) / aspect / 1, -10]}>
            {"0" + (index + 1)}
            {/* Arka Plan Indexi */}
          </Text>
        </Block>
      </group>
    </Block>
  )
}

function Content() {
  const images = useLoader(
    TextureLoader,
    state.paragraphs.map(({ image }) => image)
  )
  useMemo(() => images.forEach((texture) => (texture.minFilter = LinearFilter)), [images])
  const { contentMaxWidth: w, canvasWidth, canvasHeight, mobile } = useBlock()
  return (
    <>
      <Block factor={1} offset={0}>
        <Block factor={1.2}>
          {mobile ? (
            <>
              <Text left size={w * 0.1} position={[-w / 1.8, -7, -1]} color="#831a76">
                HETEROMETA
              </Text>
              <Text left size={w * 0.1} position={[-w / 1.8, -8, -1]} color="#831a76">
                LITEPAPER
              </Text>
            </>
          ) : (
            <>
              <Text left size={w * 0.1} position={[-w / 2.2, -7, -1]} color="#831a76">
                HETEROMETA
              </Text>
              <Text left size={w * 0.1} position={[-w / 2.2, -10, -1]} color="#831a76">
                LITEPAPER
              </Text>
            </>
          )}
        </Block>
        {/* <Block factor={1.0} offset={0.5}>
          {mobile ? (
            <Html className="center-quota-mobile" style={{ color: "white" }} position={[-w / 2, -8, -1]}>
              HeteroMeta Litepaper{mobile ? <br /> : " "}is loading...
            </Html>
          ) : (
            <Html className="center-quota" style={{ color: "white" }} position={[-w / 10.5, -10, -1]}>
              HeteroMeta Litepaper{mobile ? <br /> : " "}is loading...
            </Html>
          )}
        </Block> */}
      </Block>
      <Block factor={1.2} offset={2.9}>
        <MultilineText top left size={w * 0.15} lineHeight={w / 5} position={[-w / 3.5, 0, -1]} color="#2fe8c3" text={"two\npoint\nzero"} />
      </Block>
      {state.paragraphs.map((props, index) => (
        <Paragraph key={index} index={index} {...props} image={images[index]} />
      ))}
      {state.stripes.map(({ offset, color, height }, index) => (
        <Block key={index} factor={-1.5} offset={offset}>
          <Plane args={[50, height, 32, 32]} shift={-4} color={color} rotation={[0, 0, Math.PI / 8]} position={[0, 0, -10]} />
        </Block>
      ))}
      <Block factor={1.25} offset={27}>
        {mobile ? (
          <Html className="center-quota-mobile" style={{ color: "white" }} position={[-canvasWidth / 2.5, -5, -1]}>
            Create Transform Share{mobile ? <br /> : " "} Earn.
          </Html>
        ) : (
          <Html className="center-quota" style={{ color: "white" }} position={[-w / 7, -8, -1]}>
            Create Transform Share{mobile ? <br /> : " "} Earn.
          </Html>
        )}
      </Block>
    </>
  )
}

function App() {
  const scrollArea = useRef()
  const onScroll = (e) => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])
  return (
    <>
      <Canvas linear dpr={[1, 2]} orthographic camera={{ zoom: state.zoom, position: [0, 0, 475] }}>
        <Suspense fallback={<Html center className="loading" children="Loading..." />}>
          <Diamonds />
          <Startup />
          <Content />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
          <Stars />
        </Suspense>
      </Canvas>
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
        {new Array(state.sections).fill().map((_, index) => (
          <div key={index} id={"0" + index} style={{ height: `${(state.pages / state.sections) * 100}vh` }} />
        ))}
      </div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
